import { store } from "@risingstack/react-easy-state";
import main from "./main";

interface Student {
  email: string;
  coupon: string;
  status: string;
  isFetching: boolean;
  setEmail(value: string): void;
  setCoupon(value: string): void;
  submit(): Promise<void>;
}

const student = store<Student>({
  email: "",
  coupon: "tye-",
  isFetching: false,
  status: "",
  setEmail: (value) => {
    student.email = value;
    student.coupon = `tye-${student.email
      .replace(/@.*/, "")
      .replace(/\./g, "-")}`;
  },
  setCoupon: (value) => {
    student.coupon = value;
  },
  submit: async () => {
    try {
      student.isFetching = true;
      student.status = "Sending...";
      const res = await fetch(
        "https://n8n.trainyourears.com/webhook/8ea57f34-943f-43fb-ab01-e68582337a5f",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            token: main.secret,
          },
          body: JSON.stringify({ coupon: student.coupon }),
        }
      );
      const json = await res.json();
      if (!json.code || json.code === 404)
        throw new Error("Code not found in response.");
      student.coupon = json.code;
      student.status = `Success. Coupon was created! (${student.coupon})`;
    } catch (error) {
      student.status = "Something went wrong. Please look at the console.";
      console.error(error);
    }
    student.isFetching = false;
  },
});

export default student;
