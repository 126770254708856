import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
} from "@chakra-ui/react";
import { view } from "@risingstack/react-easy-state";
import { useState } from "react";
import main from "../stores/main";

const PasswordInput = view(() => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <Input
        pr="4.5rem"
        type={show ? "text" : "password"}
        placeholder="Enter password"
        value={main.secret}
        onChange={(e) => main.setSecret(e.target.value)}
      />
      <InputRightElement width="4.5rem">
        <Button h="1.75rem" size="sm" onClick={handleClick}>
          {show ? "Hide" : "Show"}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
});

const Secret = view(() => (
  <Box>
    <FormControl id="token">
      <FormLabel>Add your secret here:</FormLabel>
      <PasswordInput />
      <FormHelperText>Required for the rest of operations.</FormHelperText>
    </FormControl>
  </Box>
));

export default Secret;
