import {
  Box,
  Button,
  Stack,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Text,
} from "@chakra-ui/react";
import { view } from "@risingstack/react-easy-state";
import student from "../../stores/student";

const Student = view(() => (
  <Stack
    as="form"
    spacing={4}
    onSubmit={(e) => {
      e.preventDefault();
      student.submit();
    }}
  >
    <FormControl id="email">
      <FormLabel>User Email:</FormLabel>
      <Input
        type="email"
        value={student.email}
        onChange={(e) => student.setEmail(e.target.value)}
      />
      <FormHelperText>The email of the user</FormHelperText>
    </FormControl>
    <FormControl id="coupon">
      <FormLabel>Coupon:</FormLabel>
      <Input
        type="text"
        value={student.coupon}
        onChange={(e) => student.setCoupon(e.target.value)}
      />
      <FormHelperText>The final coupon that will be created</FormHelperText>
    </FormControl>
    <Box>
      <Button type="submit" disabled={student.isFetching}>
        Create Coupon
      </Button>
    </Box>
    <Text>{student.status}</Text>
  </Stack>
));

export default Student;
