import {
  Flex,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Container,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import Secret from "./secret";
import AddUser from "./add-user";
import StudentCoupon from "./student-coupon";

export const App = () => {
  const bg = useColorModeValue("gray.100", "gray.700");

  return (
    <Tabs colorScheme="gray">
      <Box bg={bg}>
        <Container maxW="container.md">
          <Box py="10">
            <Heading>TYE Back Office</Heading>
          </Box>
          <Flex justify="space-between">
            <TabList>
              <Tab>Secret</Tab>
              <Tab>Add User</Tab>
              <Tab>Student Coupon</Tab>
            </TabList>
            <ColorModeSwitcher />
          </Flex>
        </Container>
      </Box>
      <Container mt="2" maxW="container.md">
        <TabPanels>
          <TabPanel>
            <Secret />
          </TabPanel>
          <TabPanel>
            <AddUser />
          </TabPanel>
          <TabPanel>
            <StudentCoupon />
          </TabPanel>
        </TabPanels>
      </Container>
    </Tabs>
  );
};

export default App;
