import { FC } from "react";
import { Input, FormControl, FormLabel } from "@chakra-ui/react";
import { view } from "@risingstack/react-easy-state";
import { O } from "ts-toolbelt";
import user from "../../stores/user";

type Keys = O.SelectKeys<typeof user["fields"], string>;

const Field: FC<{ name: Keys; type: string }> = view(({ name, type }) => (
  <FormControl id={name} pb={4}>
    <FormLabel fontSize="sm">{name}</FormLabel>
    <Input
      type={type}
      size="sm"
      value={user.fields[name]}
      onChange={(e) => user.setStringField(e.target.value, name)}
    />
  </FormControl>
));

export default Field;
