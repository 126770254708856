import { store } from "@risingstack/react-easy-state";

const main = store({
  secret: "",
  setSecret: (secret: string) => {
    main.secret = secret;
  },
});

export default main;
