import { store } from "@risingstack/react-easy-state";
import { O } from "ts-toolbelt";
import main from "./main";

function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

interface UserStore {
  status: string;
  fields: {
    FirstName: string;
    LastName: string;
    Email: string;
    OrderID: string;
    LicenseKey: string;
    CountryCode: string;
    Origin: string;
    "Personal-v2": string;
    "Public-v2": string;
    FirstEmail: boolean;
    GiftMessage: string;
  };
  setStringField: (
    value: string,
    name: O.SelectKeys<UserStore["fields"], string>
  ) => void;
  setBooleanField: (
    value: boolean,
    name: O.SelectKeys<UserStore["fields"], boolean>
  ) => void;
  pasteFromSendy(value: string): void;
  pasteFromSheet(value: string): void;
  reset(): void;
  addToSendy(): void;
  sendPurchaseEmail(): void;
}

const user: UserStore = store({
  status: "",
  fields: {
    FirstName: "",
    LastName: "",
    Email: "",
    OrderID: "",
    LicenseKey: "",
    CountryCode: "",
    Origin: "Web",
    "Personal-v2": "3",
    "Public-v2": "0",
    FirstEmail: false,
    GiftMessage: "",
  },

  setStringField: (value, name) => {
    user.fields[name] = value;
  },

  setBooleanField: (value, name) => {
    user.fields[name] = value;
  },

  pasteFromSendy: (value) => {
    const [, FirstName] = /Name: (.+?)\n/.exec(value) || [];
    const [, Email] = /Email: (.+?)\n/.exec(value) || [];
    const [, OrderID] = /(?:OrderID|order_id): (.+?)\n/.exec(value) || [];
    const [, LicenseKey] =
      /(?:LicenseKey|license_key): (.+?)\n/.exec(value) || [];
    const [, LastName] = /(?:LastName|surname): (.+?)\n/.exec(value) || [];
    const [, CountryCode] =
      /Country: .*?\((.+?)\)\n/.exec(value) ||
      /country: (.+?)\n/.exec(value) ||
      [];

    user.fields.FirstName = capitalizeFirstLetter(FirstName);
    user.fields.LastName = capitalizeFirstLetter(LastName);
    user.fields.Email = Email;
    user.fields.OrderID = OrderID;
    user.fields.LicenseKey = LicenseKey;
    user.fields.CountryCode = CountryCode;
  },

  pasteFromSheet: (value) => {
    const [LicenseKey, Email, FirstName, LastName, OrderID, CountryCode] =
      value.match(/([^\s]+)/g) || [];

    user.fields.FirstName = capitalizeFirstLetter(FirstName);
    user.fields.LastName = capitalizeFirstLetter(LastName);
    user.fields.Email = Email;
    user.fields.OrderID = OrderID;
    user.fields.LicenseKey = LicenseKey;
    user.fields.CountryCode = CountryCode;
  },

  reset: () => {
    user.fields = {
      FirstName: "",
      LastName: "",
      Email: "",
      OrderID: "",
      LicenseKey: "",
      CountryCode: "",
      Origin: "Web",
      "Personal-v2": "3",
      "Public-v2": "0",
      FirstEmail: false,
      GiftMessage: "",
    };
  },

  addToSendy: async () => {
    try {
      user.status = "Adding user to Sendy...";
      const res = await fetch(
        "https://n8n.trainyourears.com/webhook/fb1077de-f186-4e2d-93b5-b3543c68aa3a",
        {
          method: "POST",
          headers: {
            Token: main.secret,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user.fields),
        }
      );
      const { sendy } = await res.json();
      if (!sendy) throw new Error("Can't add to Sendy.");
      user.status = `Sendy: ${sendy === 1 ? "Ok." : sendy}`;
    } catch (error) {
      user.status = `Something went wrong: ${error.message}`;
      console.error(error);
    }
    await new Promise((resolve) => setTimeout(resolve, 3000));
    user.status = "";
  },

  sendPurchaseEmail: async () => {
    try {
      user.status = "Sending purchase email again...";
      const res = await fetch(
        "https://n8n.trainyourears.com/webhook/f3b4a388-ae1e-4217-932e-0eec21e04aa6",
        {
          method: "POST",
          headers: {
            Token: main.secret,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user.fields),
        }
      );
      const { Message, ErrorCode } = await res.json();
      if (!Message)
        throw new Error(`Email couldn't be sent. Error code: ${ErrorCode}`);
      user.status = `Purchase Email Sent: Ok`;
    } catch (error) {
      user.status = `Something went wrong: ${error.message}`;
      console.error(error);
    }
    await new Promise((resolve) => setTimeout(resolve, 3000));
    user.status = "";
  },
});

export default user;
