import { FC } from "react";
import { Checkbox, FormControl } from "@chakra-ui/react";
import { view } from "@risingstack/react-easy-state";
import { O } from "ts-toolbelt";
import user from "../../stores/user";

type Keys = O.SelectKeys<typeof user["fields"], boolean>;

const CheckboxField: FC<{ name: Keys }> = view(({ name }) => (
  <FormControl id={name} pb={4}>
    <Checkbox
      isChecked={user.fields[name]}
      onChange={(e) => user.setBooleanField(!!e.target.checked, name)}
      fontSize="sm"
    >
      {name}
    </Checkbox>
  </FormControl>
));

export default CheckboxField;
