import { FC, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Textarea,
  Stack,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";

type PasteModalType = FC<{
  name: "Sendy" | "Sheet";
  onSubmit: (value: string) => void;
}>;

const PasteModal: PasteModalType = ({ name, onSubmit }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [textarea, setTextarea] = useState("");

  return (
    <>
      <Button size="sm" mr={[0, 2]} mb={2} onClick={onOpen}>
        Paste from {name}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Paste from {name}</ModalHeader>
          <ModalCloseButton />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit(textarea);
              setTextarea("");
              onClose();
            }}
          >
            <ModalBody>
              <Textarea
                value={textarea}
                onChange={(e) => setTextarea(e.target.value)}
              />
            </ModalBody>

            <ModalFooter>
              <Stack direction="row">
                <Button type="submit" colorScheme="green">
                  Paste
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </Stack>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PasteModal;
