import { Flex, Button, SimpleGrid, Box, Heading, Text } from "@chakra-ui/react";
import { view } from "@risingstack/react-easy-state";
import user from "../../stores/user";
import Field from "./field";
import Checkbox from "./checkbox";
import PasteModal from "./paste-modal";

const ActionButton: typeof Button = (props) => (
  <Button size="sm" mr={[0, 2]} mb={2} {...props} />
);

const AddUser = view(() => (
  <>
    <SimpleGrid columns={[1, 2]} spacing={10}>
      <Box>
        <Field name="FirstName" type="text" />
        <Field name="LastName" type="text" />
        <Field name="Email" type="email" />
        <Field name="OrderID" type="text" />
        <Field name="LicenseKey" type="text" />
        <Field name="CountryCode" type="text" />
      </Box>
      <Box>
        <Heading size="md" pb={4}>
          Options
        </Heading>
        <Field name="Origin" type="text" />
        <Field name="Personal-v2" type="text" />
        <Field name="Public-v2" type="text" />
        <Checkbox name="FirstEmail" />
        <Field name="GiftMessage" type="text" />
        <Heading size="md" py={4}>
          Utils
        </Heading>
        <Flex direction={["column", "column", "row"]} wrap="wrap">
          <PasteModal name="Sendy" onSubmit={user.pasteFromSendy} />
          <PasteModal name="Sheet" onSubmit={user.pasteFromSheet} />
        </Flex>
        <Heading size="md" pt={6} pb={4}>
          Actions
        </Heading>
        <Flex direction={["column", "column", "row"]} wrap="wrap">
          <ActionButton
            colorScheme="green"
            onClick={user.addToSendy}
            isDisabled={!user.fields.Email || !!user.status}
          >
            Add to Sendy
          </ActionButton>
          <ActionButton
            colorScheme="orange"
            onClick={user.sendPurchaseEmail}
            isDisabled={!user.fields.Email || !!user.status}
          >
            Send Purchase Email
          </ActionButton>
          <ActionButton colorScheme="red" onClick={user.reset}>
            Reset
          </ActionButton>
        </Flex>
      </Box>
    </SimpleGrid>
    <Text>{user.status}</Text>
  </>
));

export default AddUser;
